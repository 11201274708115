import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
         <path d="M1463 3479 c-10 -10 -13 -96 -13 -361 l0 -348 -100 0 c-60 0 -100 -4
-100 -10 0 -7 423 -10 1250 -10 827 0 1250 3 1250 10 0 6 -132 10 -375 10
l-375 0 0 334 c0 193 -4 346 -10 360 l-10 26 -154 0 c-143 0 -156 -1 -166 -19
-6 -13 -10 -145 -10 -360 l0 -341 -160 0 -159 0 14 41 c21 57 20 285 0 363
-32 122 -112 217 -233 274 l-77 37 -280 3 c-216 3 -283 0 -292 -9z m511 -322
c40 -26 59 -74 64 -159 6 -110 -4 -166 -38 -200 -27 -27 -32 -28 -129 -28
l-101 0 0 206 0 206 89 -4 c61 -3 97 -9 115 -21z"/>
<path d="M1320 2515 l0 -95 26 0 c41 0 64 18 64 48 0 16 -5 33 -11 39 -8 8 -8
13 0 17 16 10 13 52 -5 70 -8 9 -29 16 -45 16 l-29 0 0 -95z m55 40 c0 -10 -6
-20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z m3 -77
c-5 -32 -28 -35 -28 -4 0 17 5 26 16 26 10 0 14 -7 12 -22z"/>
<path d="M1574 2597 c-3 -8 -4 -49 -2 -93 l3 -79 43 -3 c33 -2 42 0 42 12 0
10 -9 16 -25 16 -20 0 -25 5 -25 25 0 18 5 25 20 25 11 0 20 7 20 15 0 8 -9
15 -20 15 -15 0 -20 7 -20 25 0 20 5 25 25 25 16 0 25 6 25 15 0 20 -78 21
-86 2z"/>
<path d="M1825 2601 c-3 -6 -5 -49 -4 -96 l2 -85 43 0 c32 0 44 4 44 15 0 9
-9 15 -25 15 -23 0 -24 3 -27 77 -3 71 -16 101 -33 74z"/>
<path d="M2080 2590 c-15 -15 -20 -33 -20 -75 0 -42 5 -60 20 -75 23 -23 33
-24 61 -10 16 9 19 22 19 85 0 63 -3 76 -19 85 -28 14 -38 13 -61 -10z m45
-75 c0 -38 -4 -60 -12 -62 -10 -4 -13 13 -13 62 0 49 3 66 13 63 8 -3 12 -25
12 -63z"/>
<path d="M2320 2606 c0 -4 6 -31 35 -163 11 -49 32 -23 49 60 20 100 20 107 2
107 -10 0 -17 -18 -22 -57 -7 -62 -17 -55 -29 20 -4 23 -11 37 -20 37 -8 0
-15 -2 -15 -4z"/>
<path d="M2580 2515 l0 -95 45 0 c33 0 45 4 45 15 0 10 -10 15 -30 15 -25 0
-30 4 -30 25 0 20 5 25 25 25 16 0 25 6 25 15 0 9 -9 15 -25 15 -20 0 -25 5
-25 25 0 21 5 25 30 25 20 0 30 5 30 15 0 11 -12 15 -45 15 l-45 0 0 -95z"/>
<path d="M2830 2515 l0 -95 34 0 c52 0 66 21 66 95 0 74 -14 95 -66 95 l-34 0
0 -95z m65 -1 c0 -46 -3 -59 -15 -59 -11 0 -16 13 -18 49 -4 56 3 79 21 73 8
-2 12 -24 12 -63z"/>
<path d="M3106 2594 c-24 -24 -19 -53 14 -82 17 -15 30 -34 30 -44 0 -22 -18
-24 -26 -3 -8 19 -34 20 -34 2 0 -16 32 -47 48 -47 7 0 21 9 32 20 24 24 18
43 -23 88 -24 24 -28 35 -19 44 9 9 13 7 18 -5 9 -23 34 -21 34 3 0 18 -26 40
-49 40 -5 0 -17 -7 -25 -16z"/>
<path d="M3350 2525 c0 -80 1 -86 24 -96 30 -14 63 2 71 34 4 13 5 50 3 82 -2
48 -7 60 -20 63 -16 3 -18 -6 -18 -78 0 -61 -3 -81 -12 -78 -9 3 -14 31 -16
81 -2 59 -6 77 -17 77 -12 0 -15 -17 -15 -85z"/>
<path d="M3614 2596 c-3 -7 -4 -49 -2 -92 3 -78 3 -79 31 -82 17 -2 37 4 48
14 20 18 26 59 9 69 -7 4 -7 12 0 25 14 27 13 37 -10 60 -23 23 -68 27 -76 6z
m60 -32 c8 -20 -1 -34 -20 -34 -8 0 -14 8 -14 18 0 33 23 44 34 16z m1 -89 c0
-11 -8 -21 -17 -23 -14 -3 -18 3 -18 23 0 20 4 26 18 23 9 -2 17 -12 17 -23z"/>
<path d="M1250 2240 c0 -6 40 -10 100 -10 l100 0 0 -252 c0 -142 4 -260 10
-268 14 -23 490 -27 584 -6 137 32 247 116 306 232 31 61 35 77 40 178 l5 111
128 3 127 3 0 -253 c0 -142 4 -260 10 -268 8 -13 65 -15 396 -18 285 -2 390 0
400 9 12 9 14 41 12 153 l-3 141 -232 3 -233 2 0 115 0 115 375 0 c243 0 375
4 375 10 0 7 -423 10 -1250 10 -827 0 -1250 -3 -1250 -10z m798 -75 c-4 -69
-21 -101 -71 -139 -21 -15 -47 -21 -117 -24 l-90 -4 0 116 0 116 141 0 141 0
-4 -65z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
